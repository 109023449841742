@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
}

.banner{
  background-image: url('../images/banner.webp');
}

.our-wines{
  background-image: url('../images/our-wines.webp');
}

.banner-foot {
  background-image: url('../images/banner-foot.svg')
}

.logos-falasco {
  background-image: url('../images/logos/logos-falasco.webp');
}

.logos-hermandad {
  background-image: url('../images/logos/logos-hermandad.webp');
}

.logos-los-haroldos {
  background-image: url('../images/logos/logos-los-haroldos.webp');
}

.logos-nampe {
  background-image: url('../images/logos/logos-nampe.webp');
}

.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size .3s ease-in-out;
}

.link-underline-eno-green {
  background-image: linear-gradient(transparent, transparent), linear-gradient(#bab035, #bab035)
}

.link-underline:hover {
  background-size: 100% 2px;
  background-position: 0 100%
}

input[type="radio"]:focus, input[type="checkbox"]:focus{
  outline: none !important;
  box-shadow: none !important;
}

.required:after {
  content:" *";
  color: red;
}
